@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@200;400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'JetBrains Mono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: url('./images/background.svg');
  background-repeat: no-repeat; */
  @apply bg-background;
}

input[type="file"] {
  display: none;
}